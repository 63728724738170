<template>
  <div style="max-width: 420px; margin: 0 auto;">
    <h1 class="h3 mb-3 font-weight-normal text-center">Please sign in</h1>
    <div class="form-group">
      <label for="input-email" class="sr-only">Email address</label>
      <input v-model="form.email" type="email" name="email" id="input-email" class="form-control" placeholder="Email address" required autofocus>
      <div class="invalid-feedback"></div>
    </div>

    <div class="form-group">
      <label for="input-password" class="sr-only">Password</label>
      <input v-model="form.password" type="password" name="password" id="input-password" class="form-control" placeholder="Password" required>
    </div>

    <button class="btn btn-primary btn-block" type="submit" @click.prevent="login">Sign in</button>

    <div class="text-center mt-3">
        <a href="#">Forgot password?</a>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'

export default {
  name: 'PageLogin',

  data () {
    return {
      form: {
        email: '',
        password: ''
      }
    }
  },

  methods: {
    ...mapActions({
      authLogin: 'auth/login'
    }),

    async login () {
      try {
        await this.authLogin(this.form)
        this.$router.push({ name: 'home' })
      }
      catch (e) {
        alert(e.message)
      }
    }
  }
}
</script>

<style>

</style>